<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="3">  <el-select @change="onChangeSiteId" v-model="site_id" placeholder="请选择">
    <el-option
      v-for="item in optionsSiteList"
      :key="item.site_id"
      :label="item.site_name"
      :value="item.site_id">
    </el-option>
  </el-select></el-col>
          <el-tooltip
            class="item"
            effect="dark"
            content="增加站点服务"
            placement="top-start"
          >
            <el-button  type="primary" @click="addPeoBtn">增加站点服务</el-button>
          </el-tooltip>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
              <!-- <el-table-column
            align="center"
            prop="site_id"
            label="站点ID"
          >
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="classification_id"
            label="分类ID"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="site_id"
            label="站点ID"
          >
          </el-table-column>
              <el-table-column
            align="center"
            prop="mould_id"
            label="商品规格类型ID"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="service_name"
            label="服务名称"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="service_cover_image"
            label="服务封面图"
          >
          <template slot-scope="scope">
             <img style="width: 100px;height: 100px;" :src="scope.row.service_cover_image" />
          </template>
          </el-table-column>
          <el-table-column label="状态" :label-width="formLabelWidth" prop="service_status">
                <template slot-scope="scope">
                    <el-switch
  v-model="scope.row.service_status"
  active-color="#13ce66"
  inactive-color="#ff4949"
  @change="changeStatus(scope.row)"
  >
</el-switch>
                </template>   
          </el-table-column> 
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="服务规格定价"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-search"
                  @click="addPeoPriceBtn(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="增加路线子项"
                placement="top-start"
              >
                <el-button
                type="success"
                  icon="el-icon-plus"
                  @click="AddPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="修改站点服务"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除站点服务"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delPicture(scope.row.service_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
       <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
         
      >
      <div slot="title" v-if="updataFalg" class="dialog-footer" >
          <div class="title" >修改站点服务</div>
        </div>
         <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
          <div class="title" >查看站点服务</div>
        </div>
        <div slot="title" v-else class="dialog-footer" >
          <div class="title" >增加站点服务</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm"  :rules="rules">
          <el-form-item label="分类名称" :label-width="formLabelWidth" prop="siteId">
                            <el-select  v-model="form.classification_id" placeholder="请选择分类名称">
    <el-option
      v-for="item in optionsClassList"
      :key="item.classification_id"
      :label="item.classification_name"
      :value="item.classification_id">
    </el-option>
  </el-select>
    </el-form-item> 
                         <el-form-item label="站点名称" :label-width="formLabelWidth" prop="siteId">
                            <el-select  v-model="form.site_id" placeholder="请选择名称">
    <el-option
      v-for="item in optionsSiteList"
      :key="item.site_id"
      :label="item.site_name"
      :value="item.site_id">
    </el-option>
  </el-select>
    </el-form-item> 
    <el-form-item label="模型名称" :label-width="formLabelWidth" prop="mouldId">
                            <el-select  v-model="form.mould_id" placeholder="请选择名称">
    <el-option
      v-for="item in optionsModuleList"
      :key="item.mould_id"
      :label="item.mould_name"
      :value="item.mould_id">
    </el-option>
  </el-select>
    </el-form-item> 
    <el-form-item label="服务名称" :label-width="formLabelWidth" prop="serviceName">
                                 <el-input v-model="form.service_name" placeholder="请输入名称"></el-input>
  </el-form-item>  
  <el-form-item
            label="服务封面图"
            prop="serviceCoverImage"
            :label-width="formLabelWidth"
          >
            <el-upload
              class="upload-demo"
              :action="imgUrl"
              :on-success="imgSuccess"
              :on-remove="imgRemove"
              :on-exceed="exceed"
              :file-list="fileList"
              :data="imgParam"
              :limit="maxLimit"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>     
          <el-form-item
            label="详情图"
            prop="validateSite_image_json"
            :label-width="formLabelWidth"
          >
            <el-upload
              class="upload-demo"
              :action="imgUrl"
              :on-success="imgSuccessArr"
              :on-remove="imgRemoveArr"
              :on-exceed="exceedArr"
              :file-list="fileListArr"
              :data="imgParam"
              :limit="maxLimitArr"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item> 
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="siteAddDate('ruleForm')"
            >添 加</el-button
          >
          <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
            >确 认</el-button
          >
         <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog>
       <!-- 增加路线子项 -->
       <el-dialog
        :visible.sync="dialogFormVisible1"
        @close="userClose(form1)"
        :append-to-body="true"         
      >
      <div slot="title"  class="dialog-footer" >
          <div class="title" >增加路线子项</div>
        </div>
        <div class="diaBox">
        <el-form :model="form1" ref="ruleForm"  :rules="rules">
          <el-form-item label="站点服务ID" :label-width="formLabelWidth" >
             <el-input disabled v-model="form1.service_id"></el-input>
          </el-form-item>
                         <el-form-item label="路线" :label-width="formLabelWidth" >
                            <el-select v-model="form1.route_id" placeholder="请选择路线">
    <el-option
      v-for="item in routerListArr"
      :key="item.route_id"
      :label="item.route_name"
      :value="item.route_id">
    </el-option>
  </el-select>
    </el-form-item>
    <el-form-item label="排序" :label-width="formLabelWidth">
      <el-input type="number" v-model="form1.subprojects_sort" placeholder="请输入排序">
      </el-input>
    </el-form-item>      
    <el-form-item label="状态" :label-width="formLabelWidth">
      <el-switch
  v-model="form1.route_status"
  active-color="#13ce66"
  inactive-color="#ff4949"
  @change="onChaRouSta"
  >
</el-switch>
    </el-form-item>  
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button  type="primary" @click="addRouterList('ruleForm')"
            >增 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import {
    PostSiteServiceList,
    PostSiteServiceAdd,
    PostSiteServiceUpdate,
    PostSiteServiceDelete,
    PostSiteServiceStatus,
    PostsiteList,
    PostServiceMouldList,
    PostRouteList,
    PostRouteListSubAdd,
    PostRouteListSubUpdate,
    PostRouteListSubDelete,
    PostClassificationList
} from "@/api/siteServer/siteServer.js"
  
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
          var validateSiteId = (rule, value, callback) => {
          if (this.form.site_id === '') {
            callback(new Error('请选择站点名称'));
          } else {
            callback();
          }
        };
          var validateMouldId = (rule, value, callback) => {
          if (this.form.mould_id === '') {
            callback(new Error('请选择模型名称'));
          } else {
            callback();
          }
        };
        var validateServiceName = (rule, value, callback) => {
          if (this.form.mould_id === '') {
            callback(new Error('请选择服务名称'));
          } else {
            callback();
          }
        };
        var validateServiceCoverImage = (rule, value, callback) => {
          if (this.form.service_cover_image === '') {
            callback(new Error('请上传服务封面图'));
          } else {
            callback();
          }
        };
        var validateServiceSiteImage = (rule, value, callback) => {
      if (this.form.service_image_json === '') {
        callback(new Error("请上传详情图"));
      } else {
        callback();
      }
    };
      //这里存放数据
      return {
           rules: {
            siteId: [
              { validator: validateSiteId, trigger: 'blur' }
            ],
            mouldId: [
              { validator: validateMouldId, trigger: 'blur' }
            ],
            serviceName: [
              { validator: validateServiceName,trigger: 'blur' }
            ],
            serviceCoverImage: [
              { validator: validateServiceCoverImage, trigger: 'blur' }
            ],
            validateSite_image_json: [
              { validator: validateServiceSiteImage, trigger: 'blur' }
            ],
          },
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        maxLimit:1,
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        site_id:'',
        form:{
            site_id:'',
            mould_id:'',
            service_name:'',
            service_cover_image:'',
            service_id:'',
            classification_id:'',
            service_status:'',
            service_image_json:''
        },
        form1:{
          route_id:'',
          service_id:'',
          subprojects_sort:0,
          route_status:true,  
          
        },
        routerListArr:[],
        optionsSiteList:[],
        optionsModuleList:[],
        maxLimitArr:9,
        props:{
          children:'sub',
          value:'id',
          label:'name'
        },
        fileListArr: [],
        //表格数据
           tableData: [],
           optionsClassList:[],
              token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
         imgParam: {
          path: "contract/template",
        },
          fileList: [],
          //控制弹窗--新增修改
        dialogFormVisible: false,
        dialogFormVisible1: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
     
      };
    },
    //监听属性 类似于data概念
    computed: {
          imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
    //          dialogFormVisible(newVal, oldVal) {
    //     if (!newVal) {
    //       this.form = this.$options.data().form;
    //       this.updataFalg=false;
    //       this.lookFalg=false;
    //       this.addFlag=false;
    //     }
    //   },
    },
    //方法集合
    methods: {

      onChaRouSta(item){
        // this.PostRouteListSubUpdate({

        // })
        //     console.log(item);
      },
      addRouterList(){
       // console.log(this.form1)
        this.PostRouteListSubAdd(this.form1);
      },
      AddPicture(item){
        this.dialogFormVisible1=true;
        this.form1.service_id=item.service_id;
        console.log(item);
      },
      addPeoPriceBtn(item){
      this.$router.push({
        path:'servePriceList',
        name:'servePriceList',
        params:{
          item:item
        }
      })
    },
        //状态更新
        changeStatus(item){
              // console.log(id,value)
              this.PostSiteServiceStatus({
                service_id:item.service_id,
                service_status:item.service_status==true?1:-1
              })
        },
          //修改站点
      updataPicture(item){
        this.dialogFormVisible=true;
          this.updataFalg=true;
          this.form={
            classification_id:item.classification_id,//分类ID
            site_id:item.site_id,//服务id
            mould_id:item.mould_id,//模型ID
            service_name:item.service_name,
            service_cover_image:item.service_cover_image,
            service_id:item.service_id,
            service_image_json: item.service_image_json,
          }
          this.fileList=[{
            url:item.service_cover_image,
            name:item.service_name
          }]
          var routeImageArr=JSON.parse(item.service_image_json);
       routeImageArr.forEach((item)=>{
        this.fileListArr.push({ name: item.title, url: item })
       })
      },
          //站点删除
      delPicture(service_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostSiteServiceDelete({
                service_id:service_id
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        
        },
            //站点增加
            siteAddDate(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
        //  console.llog(this.form)
          this.PostSiteServiceAdd(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        //站点修改
        updSiteSer(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
          this.PostSiteServiceUpdate(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        //站点
        onChangeSiteId(value){
           this.PostSiteServiceList({
            site_id:value,
            page:1,
            page_num:10
           })
        },
      //增加站点
      addPeoBtn(){
          this.dialogFormVisible=true;
          this.addFlag=true;
      },
      //查看站点
      lookSite(){
          
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
       // this.getPeopleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
      //  this.getPeopleList(data);
      },
            exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
        //图片集数量超出事件
    exceedArr() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
      //导入图片事件
      imgSuccess(response, file, fileList) {
          // console.log(response)
        this.form.service_cover_image = response.result.url;
      },
      //图片集导入事件
    imgSuccessArr(response, file, fileList) {
      var arr=null
      if(this.form.service_image_json == ''){
        arr=[]
      }else{
        arr = JSON.parse(this.form.service_image_json);
      }
      arr.push(response.result.url)
     // console.log(arr)
      this.form.service_image_json = JSON.stringify(arr);
    },
       //移除图片集的事件
       imgRemoveArr(file, fileList) {
     // console.log(file)  
     let arr = JSON.parse(this.form.service_image_json);
       // console.log(arr,arr.indexOf(file.url))
        arr.splice(
          arr.indexOf(file.url),1
        );
        this.form.service_image_json=JSON.stringify(arr);
    },
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.service_cover_image = "";
      },
             //添加事件 退出弹窗
      userClose(e) {
        this.form=this.$options.data().form;
        this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
        (this.fileList = []), console.log("close");
        (this.fileListArr = []), console.log("close");
      },
      /**-----------------网络请求生命周期函数--------------------- */
      //分类名称
      async PostClassificationList(data){
          let res=await PostClassificationList(data);
          if(res.data.status==1){
            this.optionsClassList=res.data.result.data;
          }
      },
      //增加路线子项
      async PostRouteListSubAdd(data){
         let res=await PostRouteListSubAdd(data);
         if(res.data.status==1){
          this.dialogFormVisible1=false;
          this.$message({
          message: res.data.message,
          type: 'success'
        });
         }
       // console.log(res)
      },
       //修改路线子项
       async PostRouteListSubUpdate(data){
         let res=await PostRouteListSubUpdate(data);
         console.log(res)
      },
       //删除路线子项
       async PostRouteListSubDelete(data){
         let res=await PostRouteListSubDelete(data);
         console.log(res)
      },
      //路线列表
      async PostRouteList(data){
        let res=await PostRouteList(data);
         if(res.data.status==1){
            this.routerListArr=res.data.result.data;
            this.form1.route_id=res.data.result.data[0].route_id
         }
      },
    //服务模型列表
     async PostServiceMouldList(data){
         let res=await PostServiceMouldList(data);
         if(res.data.status==1){
            this.optionsModuleList=res.data.result;
         }
    },
      //站点列表
      async PostsiteList(data){
          let res=await PostsiteList(data);
          if(res.data.status==1){
             this.optionsSiteList=res.data.result.data;
             this.site_id=res.data.result.data[0].site_id;
             this.PostSiteServiceList({
                site_id:res.data.result.data[0].site_id,
                page:1,
                page_num:10
             });
          }
         
      },
       //站点服务列表
      async PostSiteServiceList(data){
          let res=await PostSiteServiceList(data);
        //  console.log(res.data)
        res.data.result.data.forEach((item)=>{
            item.service_status=item.service_status==1?true:false
          })
          this.tableData=res.data.result.data;
          // console.log(res.data.result.data)
          this.getInfo={
              page_code:res.data.result.last_page,
              list_rows:res.data.result.per_page
          }
            this.exp.num=res.data.result.total;
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
                  //站点服务增加
                  async PostSiteServiceAdd(dataObj){
         let res=await PostSiteServiceAdd(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        site_id:this.form.site_id
      };
       this.PostSiteServiceList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
           //站点服务修改
           async PostSiteServiceUpdate(dataObj){
         let res=await PostSiteServiceUpdate(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        site_id:this.form.site_id
      };
       this.PostSiteServiceList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
        //站点服务删除
        async PostSiteServiceDelete(dataObj){
         let res=await PostSiteServiceDelete(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({ 
          message: res.data.message,
          type: 'success',
          
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        site_id:this.site_id
      };
       this.PostSiteServiceList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
       //站点服务更新
       async PostSiteServiceStatus(dataObj){
         let res=await PostSiteServiceStatus(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({ 
          message: res.data.message,
          type: 'success',
          
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
        site_id:this.site_id
      };
       this.PostSiteServiceList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
    },
  
    
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      this.PostsiteList();
      this.PostServiceMouldList();
      this.PostRouteList();
      this.PostClassificationList();
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
  
  <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>