import { post,get } from '@/utils/request'

//站点服务列表
export function PostSiteServiceList(data) {
    return post('adminapi/site_service_list',data)
}
//站点服务增加
export function PostSiteServiceAdd(data) {
    return post('adminapi/site_service_add',data)
}
//站点服务修改
export function PostSiteServiceUpdate(data) {
    return post('adminapi/site_service_update',data)
}
//站点服务删除
export function PostSiteServiceDelete(data) {
    return post('adminapi/site_service_delete',data)
}
//站点服务状态更新
export function PostSiteServiceStatus(data) {
    return post('adminapi/site_service_status_update',data)
}
//站点列表
export function PostsiteList(data) {
    return post('adminapi/site_list',data)
}
//模型列表
export function PostServiceMouldList(data) {
    return post('adminapi/service_mould_list',data)
}
//路线列表
export function PostRouteList(data) {
    return post('adminapi/route_list',data)
}
//路线子项增加
export function PostRouteListSubAdd(data) {
    return post('adminapi/route_subprojects_add',data)
}

//路线子项更新
export function PostRouteListSubUpdate(data) {
    return post('adminapi/route_subprojects_update',data)
}
//路线子项删除
export function PostRouteListSubDelete(data) {
    return post('adminapi/route_subprojects_delete',data)
}
//站点服务列表
export function PostClassificationList(data) {
    return post('adminapi/classification_list',data)
}